@import "./styles/utility";

.App {
  text-align: center;
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-color: #000;
  background-size: cover;
  background-position: 28% center;
  background-repeat: no-repeat;
}

.scrollContainer {
  height: 100%;
  width: 100%;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  align-content: center;
  justify-content: center;
  padding: 1rem;
  height: 100%;
}

@include md() {
  .gridContainer {
    padding: 4vw;
    grid-template-columns: repeat(6, 300px);
  }
}
