.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  opacity: 0;
  animation: modalIn 500ms ease 1000ms forwards;
  background-color: #e3e3e3;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .close {
    position: fixed;
    bottom: 0.5rem;
    right: 0.5rem;
    border-radius: 1rem;
    background-color: #e40a2d;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border: 0;

    &:active {
      background-color: darken(#e40a2d, 10%);
    }
  }
}

@keyframes modalIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
