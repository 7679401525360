@import "../styles/utility";

.doorOuter {
  width: 100%;
  height: 100%;
  padding: 1.5rem;
}

.doorFrame {
  width: 100%;
  aspect-ratio: 1/1;
  position: relative;
  border: 8px solid #eee;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.doorInner {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 2px;
}

.doorOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .day {
    @include md() {
      font-size: 4rem;
    }

    font-family: "Mountains of Christmas", cursive;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50%;
    aspect-ratio: 1/1;
    border-radius: 100%;
    color: #000;
    background-color: #fff;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity ease 300ms 150ms;
    transform: translateX(-50%) translateY(-50%);
  }

  .left,
  .right {
    position: absolute;
    width: 50%;
    top: 0;
    bottom: 0;
    background: darken(#e40a2d, 5%);
    transition: transform ease 1s;
    transform: perspective(500px) rotateY(0);
    overflow: hidden;

    .panel {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      right: 0.5rem;
      bottom: 0.5rem;
      background-color: rgba(0, 0, 0, 0.1);
      border-top: 2px solid rgba(255, 255, 255, 0.2);
      border-left: 2px solid rgba(255, 255, 255, 0.2);
      border-right: 2px solid rgba(0, 0, 0, 0.1);
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    }
  }

  .left {
    left: 0;
    transform-origin: left;
  }

  .right {
    right: 0;
    transform-origin: right;
  }
}

.doorFrame {
  &.open {
    .left {
      transform: perspective(500px) rotateY(-110deg);
    }

    .right {
      transform: perspective(500px) rotateY(110deg);
    }

    .day {
      opacity: 0;
    }
  }
}
